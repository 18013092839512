import {FaCheck } from "react-icons/fa";
import apiService from "../../logic/apiService";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast, updateTitle } from "../../app/redux-reducers/contextProvider";
export default function KlusjesDashboard() {
    const [tasks, setTasks] = useState(undefined);
	const dispatch = useDispatch()

    useEffect(() => {
		dispatch(updateTitle("Klusjes - Mijn Taken"));

        const fetchTasks = async () => {
            apiService.get("/task/own").then((res) => {
				if (res.success === false)
					return dispatch(showToast({ message: res.message, type: 3 }))
				setTasks(res)
			})
        }
        fetchTasks();
    }, []);

    return (
        <div className="overflow-scroll w-full no-scrollbar">
			{(!tasks || tasks.length === 0) && <p className="text-center flex justify-center items-center w-full text-2xl">Geen taken gevonden.</p>}
			{tasks && tasks.map(task => (
            	<Task key={task.taskID} task={task}/>
			))}
        </div>
    )
}

function Task({task}) {
    const [isCompleted, setIsCompleted] = useState(false);

	async function completeTask() {
		const res = await apiService.put(`/task/complete/${task.taskID}`)
		setIsCompleted(res.completed)
	}

	return (
		<div className="max-w-3xl w-full flex flex-col gap-2.5 px-5 py-3 last:border-none border-b border-b-dark-primary min-h-20 last:mb-20">
			{/* Content */}
			<div className="flex gap-2.5 flex-row">
				{/* Profile picture of taskCreator */}
				<div className="size-12 flex-none overflow-hidden text-xl rounded-full bg-blue-500 text-white flex justify-center items-center">{task.createdBy.charAt(0)}</div>
				{/* Content */}
				<div className="flex flex-col gap-2">
					{/* Header */}
					<div className="flex flex-col">
						<div className="w-full items-center flex-row flex gap-2">
							<h5 
								className={`font-bold text-base ${
									new Date(task.deadline).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) 
									? 'text-red-500' 
									: new Date(task.deadline).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) 
										? 'text-orange-500' 
										: ''
								}`}>
								{task.title}
							</h5>	
							<span className="text-sm">&#8226;</span>
							<h5 className="font-bold text-base">@{task.createdBy}</h5>
							<span className="text-sm">&#8226;</span>
							<div className="flex items-center flex-row gap-2 justify-end">
                                <button
                                    onClick={() => completeTask()}
                                    className="relative size-6"
                                >
                                    <FaCheck
                                        className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 duration-[400ms] ease-out text-green-500 ${isCompleted ? "size-full" : "size-0"}`}
                                    />
                                    <FaCheck
                                        className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 duration-150 ease-in-out ${isCompleted ? "size-0" : "size-full"}`}
                                    />
                                </button>
                            </div>
						</div>
						<span className="text-sm text-dark-text-0.5">
							{formatDate(task.deadline)}, @{task.accounts.map(account => account).join(', @')}
						</span>
					</div>
					{/* Body */}
					<div>
						<p className="text-sm">
							{task.description}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

function formatDate(date) {
	// Format to : Monday 1 January 2021 (IN DUTCH)
	const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return new Date(date).toLocaleDateString('nl-NL', options);
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlcFhy9hQqnDASJX_x4GnfbKr6rTrExD8",
  authDomain: "homeapps-da679.firebaseapp.com",
  projectId: "homeapps-da679",
  storageBucket: "homeapps-da679.firebasestorage.app",
  messagingSenderId: "938739252096",
  appId: "1:938739252096:web:07ee77b8ea09b21c621f65",
  measurementId: "G-422QLXK1MN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export { messaging, getToken };
import React, { useEffect, useRef } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useGetDataQuery } from "../logic/apiSlice";
import { messaging, getToken } from "../logic/firebase";
import AuthLayout from "../components/layout/AuthLayout";
import Layout from "../components/layout/Layout";
import ToastContainer from "../components/layout/ToastContainer";

import Login from "../pages/auth/Login";
import Feed from "../pages/Feed";
import NotFound from "../pages/notFound";
import Signup from "../pages/auth/Signup";
import KlusjesLayout from "../components/layout/klusjesLayout";
import KlusjesDashboard from "../pages/klusjes/dashboard";
import KlusjesCreate from "../pages/klusjes/create";
import KlusjesCreated from "../pages/klusjes/created";
import Apps from "../pages/Apps";
import apiService from "../logic/apiService";

function App() {
    return (
        <>
            <ToastContainer />

            <Routes>
                <Route path="auth" element={<AuthLayout />}>
                    <Route path="login" element={<Login />} />
                    <Route path="signup" element={<Signup />} />
                </Route>

				<Route path="/" element={<ProtectedRoute/>}>

                    <Route path="/" element={<Layout />}>
                        <Route path="feed" element={<Feed />} />
                        <Route path="apps" element={<Apps />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>

                    <Route path="klusjes" element={<KlusjesLayout />}>
                        <Route path="dashboard" element={<KlusjesDashboard />} />
                        <Route path="create" element={<KlusjesCreate />} />
                        <Route path="created" element={<KlusjesCreated />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
				</Route>


                {/* This route will NOT go through the protectedRoutes middleware. This has been tested and works, i suggest never touching it again. */}
                <Route path="/" element={<Layout />}>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
}

const ProtectedRoute = () => {
    const loadingComponent = useRef(null);
    let { isLoading, isSuccess } = useGetDataQuery("/account/user");

    useEffect(() => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                requestFCMToken();
            } else {
                console.log('Permission not granted for notifications');
            }
        });
    }, []);

    return (
        <>
            <div
                ref={loadingComponent}
                onAnimationEnd={() => {
                    // Hide the div after the animation ends
                    if (loadingComponent.current) {
                        loadingComponent.current.classList.add("hidden");
                    }
                }}
                className={`bg-dark-bg text-dark-primary fixed inset-0 z-50 flex items-center justify-center ${isSuccess && "animate-fadeOut"}`}
            >
                <h1 className="pattaya my-6 animate-pulse text-3xl font-bold">
                    HomeApps
                </h1>
            </div>
            {isSuccess && <Outlet />}
            {!isLoading && !isSuccess && <Navigate to="/auth/login" />}
        </>
    );
};

export async function requestFCMToken() {
    try {
      const currentToken = await getToken(messaging, { vapidKey: 'BJf9-9cKQDrUiLidJP8YfoBNVsA5YxjVEghvfJV9kjvJKU9xBYDL0RfNE31T-VJCNMKX1cGvi1tz9D3g6h9Ts7A' });
      if (currentToken) {
        apiService.post("/notification/save-token", { token: currentToken });
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
    }
}

export default App;

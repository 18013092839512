import React from "react";

import { useGetDataQuery } from "../../logic/apiSlice";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
    IoHomeOutline,
    IoHome,
    IoSearchOutline,
    IoSearch,
    IoNotifications,
    IoNotificationsOutline,
    IoMail,
    IoMailOutline,
    IoApps,
} from "react-icons/io5";
import { TbSettings, TbSettingsFilled } from "react-icons/tb";
import { MdAccountCircle, MdApps, MdOutlineAccountCircle } from "react-icons/md"; // to be implemented
import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { showToast } from "../../app/redux-reducers/contextProvider";
import apiService from "../../logic/apiService";

function Header() {
    const title = useSelector((state) => state.context.title);
    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { refetch, data: accountData, isSuccess } = useGetDataQuery("/account/user");

    function logOut() {
        dispatch(showToast({ message: "Logging out...", level: 0 }));
        // Clear Cache

        apiService.post("/auth/logout").then(() => {
            refetch();
            navigate("/auth/login");
        });
    }
    return (
        isSuccess && (
            <div className="w-full justify-start sm:flex sm:w-20">
                <div className="w-full sm:relative sm:flex sm:h-[calc(100vh-7rem)] sm:w-auto sm:flex-col sm:justify-between">
                    <nav className="bg-dark-bg sm:bg-dark-bg-shade border-b-dark-primary fixed top-0 z-40 flex h-14 w-full flex-row items-center justify-between border-b px-6 py-2 sm:relative sm:h-auto sm:w-auto sm:rounded-xl sm:border-0 sm:p-2">
                        <button onClick={() => logOut()} className="sm:hidden">
                            <MdLogout className="text-dark-primary size-10" />
                        </button>
                        <h5 className="text-xl font-bold sm:hidden">{title}</h5>
                        <Link
                            to="/apps"
                            className={`sm:hidden`}
                        >
                            {location.pathname.includes("/apps") ? (
                                <IoApps className="text-dark-secondary size-9" />
                            ) : (
                                <IoApps className="text-dark-primary size-9" />
                            )}
                        </Link>
                        <div className="hidden sm:block">
                            {/* <Link
                                to={`/profile/${accountData?.accountID}/posts`}
                                className={isSuccess ? "" : `pointer-events-none`}
                            > */}
                                <div className="flex-row items-center gap-2 lg:flex lg:px-1">
                                    <div
                                        className={`border-dark-secondary box-border size-10 rounded-full sm:flex sm:size-14 sm:items-center ${location.pathname.includes("/profile") ? "border-[3px]" : "border-2"} relative z-10`}
                                    >
                                        <div className="size-full text-xl rounded-full bg-blue-500 text-white flex justify-center items-center">{accountData.name.charAt(0)}</div>
                                    </div>
                                    <span className="hidden font-bold lg:flex">
                                        @{accountData.username}
                                    </span>
                                </div>
                            {/* </Link> */}
                        </div>

                    </nav>
                </div>
            </div>
        )
    );
}

export default Header;

import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../ui/Footer";
import Header from "../ui/Header";

import { IoBarChart, IoBarChartOutline, IoCreate, IoCreateOutline } from "react-icons/io5";
import { BiCrown, BiSolidCrown } from "react-icons/bi";


function Layout() {
    const title = useSelector((state) => state.context.title);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === "/klusjes") {
            navigate("/klusjes/dashboard");
        }
    }, []);

    return (
        <div className="flex-col sm:relative sm:flex">
            <span className="text-dark-text z-30 hidden font-bold sm:fixed sm:flex sm:h-14 sm:w-full sm:items-center sm:justify-center sm:gap-2 sm:text-xl">
                {title}
            </span>
            <div className="bg-dark-bg text-dark-text flex min-h-dvh flex-col items-center sm:flex-row-reverse sm:items-center sm:justify-center sm:gap-4 sm:py-14 md:gap-6">
                <Header/>
                <div className="sm:bg-dark-bg-shade flex w-full flex-col items-center justify-center pt-14 sm:h-[calc(100vh-7rem)] sm:max-w-md sm:justify-start sm:rounded-xl sm:pt-0 sm:shadow-xl md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl">
                    <Outlet />
                </div>
                <Footer
                    settings={[
						{
							path: "/klusjes/dashboard",
							icon: IoBarChartOutline,
							iconActive: IoBarChart,
						},
						{
							path: "/klusjes/create",
							icon: IoCreateOutline,
							iconActive: IoCreate,
						},
						{
							path: "/klusjes/created",
							icon: BiCrown,
							iconActive: BiSolidCrown,
						}
                    ]}
                />
            </div>
        </div>
    );
}

export default Layout;

import { useNavigate } from "react-router-dom";

export default function NotFound() {

    const navigate = useNavigate()

    return (
        <div style={{height: 'calc(100vh - 3.5rem)'}} className="w-10/12 flex items-center flex-col justify-center h-screen">
            <h1 className="text-6xl font-bold text-dark-primary">404, Pagina niet gevonden</h1>
            <p className="mt-4 text-lg text-dark-text text-center">{generatePunchline()}</p>
            <a onClick={() => navigate('/feed')} className="mt-6 inline-block px-6 py-3 text-white bg-dark-secondary rounded-lg hover:bg-dark-accent">
                Terug naar start pagina
            </a>
        </div>
    );
}

function generatePunchline() {
    const punchlines = [
        "Het lijkt erop dat deze pagina op vakantie is gegaan!",
        "Oeps! Deze pagina is net zo moeilijk te vinden als de muis.",
        "Dit is awkward. De pagina die je zoekt is verloren gegaan in het web van het internet.",
        "Deze pagina is zoals je huiswerk: gewoon verdwenen!",
        "Het lijkt erop dat deze pagina niet meer bestaat. We raden je aan een goede zoektocht te doen!",
        "De pagina die je zoekt, is waarschijnlijk op een avontuurlijke expeditie.",
        "De pagina is niet kwijt, ik weet gewoon even niet meer waar hij is...",
        "Zelfs Google kon deze niet vinden!",
        "Heb je al geprobeerd het universum te bellen?",
        "Deze pagina is zo geheim dat zelfs wij hem niet kunnen vinden!",
        "Het lijkt erop dat deze pagina een pauze heeft genomen.",
        "Deze pagina is net als een ninja, onzichtbaar!",
        "Sorry, deze pagina is op een geheime missie.",
        "Deze pagina is verhuisd zonder een adres achter te laten.",
        "Het lijkt erop dat deze pagina een omweg heeft genomen.",
        "Deze pagina is zo zeldzaam als een eenhoorn.",
        "Deze pagina is in rook opgegaan!",
        "Deze pagina is op zoek naar zichzelf.",
        "Deze pagina is zo ongrijpbaar als de wind.",
        "Het lijkt erop dat deze pagina een dutje doet.",
        "Deze pagina is net als een spook, onzichtbaar en ongrijpbaar.",
        "Deze pagina is op een avontuur in een ander universum.",
        "Deze pagina is zo verloren als een sok in de was.",
        "Deze pagina is op een geheime missie voor de geheime dienst.",
        "Deze pagina is zo moeilijk te vinden als een naald in een hooiberg.",
        "Deze pagina is op vakantie naar een onbekende bestemming.",
        "Deze pagina is verdwenen in de Bermuda Driehoek.",
        "Deze pagina is zo mysterieus als het monster van Loch Ness.",
        "Hand in Hand, kameraden, deze pagina is verdwenen!",
        "Komen wij uit rotterdam? ...",
        "Merry Christmas! Oh wacht, het is geen kerst.",
        "Maar... waar is de pagigina?",
        "Mijn ideeën raken op, hebben jullie suggesties?",
        "Daan, waar is de pagina?",
        "Oh, shit deze pagina ben ik vergeten te maken...",
        "Deze pagina staat net zolaag op mijn todo list als ajax in de eredivsie staat!",
        "Wist je dat... de helft van deze teksten door AI gemaakt zijn?",
        "Mama haar budget voor deze pagina was op, noor heeft te veel kleding gekocht...",
        "💩",
        "🐓h=d 🐟-v 🟡el=w🔔-b🕊️-u f=g",
    ];
    return punchlines[Math.floor(Math.random() * punchlines.length)]
}
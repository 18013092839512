import { FaRegComment, FaComment, FaHeart, FaRegHeart, FaCheck } from "react-icons/fa";
import { IoShareSocialOutline, IoShareSocial, IoClose } from "react-icons/io5";
import apiService from "../../logic/apiService";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal"
import { useDispatch } from "react-redux";
import { showToast } from "../../app/redux-reducers/contextProvider";
import { updateTitle } from "../../app/redux-reducers/contextProvider";
export default function KlusjesCreate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [accounts, setAccounts] = useState([]);
    const [search, setSearch] = useState("");
    const [data, setData] = useState({
        title: "",
        description: "",
        type: "single",
        deadline: new Date().toISOString().slice(0, 10),
        assignTo: [],
        repeatFrom: new Date().toISOString().slice(0, 10),
        repeatUntil: new Date().toISOString().slice(0, 10),
        repeatEvery: 1,
        repeatUnit: "DAYS",
    })

    const [isModalOpen, setIsModalOpen] = useState(false);

    let assigneeCount = 0;
    let accountCount = 0;

    useEffect(() => {
		dispatch(updateTitle("Klusjes - Taak maken"));

        const fetchData = async () => {
            let accounts = await apiService.get("/account")
            setAccounts(accounts.result)
        }

        fetchData();
    }, [])

    const handleAssignee = (accountID) => {
        let newValue = []
        if (data.assignTo.includes(accountID)) {
            newValue = data.assignTo.filter(id => id !== accountID);
        } else {
            newValue = [...data.assignTo, accountID];
        }

        setData(prev => ({
            ...prev,
            assignTo: newValue
        }));
    }

    function saveTask() {
        apiService.post('/task', data).then((res) => {
            if (!res.success) {
                return dispatch(
                    showToast({
                        message: `Er is iets fout gegaan tijdens het opslaan van de taak: ${res.message}`,
                        level: 3,
                    }),
                );
            }
            navigate('/klusjes/dashboard')
        })
    }

    return (
        <div className="p-2 w-full flex justify-center flex-col items-center">
            {/* Inputs Below: */}
            <h5 className="font-bold text-base">Aanmaken nieuwe taak</h5>
            <div className="last:mb-20 flex w-full flex-col gap-2 mt-2">
                {/* Title */}
                <Input label="Titel" name="title" charLimit={100} data={data} setData={setData}/>
                {/* Description */}
                <Input label="Omschrijving" name="description" charLimit={2000} data={data} setData={setData}/>
        
                {/* Asign */}
                <div>
                    <div className="w-full items-center flex-row flex gap-2">
                        <h5 className="font-bold text-base">Taak Toewijzen</h5>
                    </div>
                    <button
                        className="w-full active:border-dark-secondary bg-dark-input-bg h-12 rounded border border-dark-primary p-2 outline-none"
                        onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                        Kies gebruikers
                    </button>

                    {isModalOpen && 
                    <Modal 
                        isOpen={isModalOpen}
                        onRequestClose={() => setIsModalOpen(false)}
                        contentLabel="Assign User Modal"
                        className="fixed inset-0 flex items-center justify-center z-40 text-dark-text"
                        overlayClassName="z-40 fixed inset-0 bg-black bg-opacity-50"

                    >
                        <div className="last:mb-20 mt-2 w-10/12 border rounded z-40 bg-dark-bg">
                            <div className="p-2 border flex justify-between items-center">
                                <h5 className="font-bold  text-sm">Koppel meerdere gebruikers aan deze taak</h5>
                                <IoClose onClick={() => setIsModalOpen(false)} className="cursor-pointer size-4"/>
                            </div>

                            <div className="p-2 border">
                                <input
                                    className="focus:border-dark-secondary bg-dark-bg border-dark-primary rounded border p-2 outline-none w-full"
                                    value={search}
                                    onChange={(event) => setSearch(event.target.value)}
                                    placeholder= "Typ of selecteer een gebruiker"
                                />
                            </div>

                            {data.assignTo.length !== 0 && 
                                <>
                                    <div className="p-2 border">
                                        <h5 className="font-bold  text-sm">Toegewezen gebruikers</h5>
                                    </div>

                                    <div className="border flex flex-col">
                                    {accounts.map(account => {

                                        if (assigneeCount >= 4) return;

                                        if (!data.assignTo.includes(account.accountID)) return;

                                        assigneeCount++;
                                        
                                        return (
                                            <div className="select-none flex items-center gap-2 p-2 hover:bg-blue-500 group" onClick={() => handleAssignee(account.accountID)} key={account.accountID}>
                                                <div className="w-5 flex justify-center items-center">{data.assignTo.includes(account.accountID) && <FaCheck className="text-green-500"/>}</div>
                                                <div className="size-6 text-sm rounded-full bg-blue-500 text-white flex justify-center items-center">{account.name.charAt(0)}</div>
                                                <div className="w-full items-center flex-row flex gap-2">
                                                    <h5 className="font-bold  text-base">{account.username}</h5>
                                                    <span className="text-sm">&#8226;</span>
                                                    <h5 className="text-gray-500 text-sm group-hover:text-white">{account.name}</h5>
                                                </div>
                                            </div>
                                        )

                                    })}
                                    </div>
                                </>
                            }

                            <div className="p-2 border">
                                <h5 className="font-bold  text-sm">Suggesties</h5>
                            </div>

                            <div className="border flex flex-col">
                                {accounts.map((account) => {

                                    if (accountCount >= 4) return;
                                    if (!account.username.toLowerCase().includes(search.toLowerCase()) && !account.name.toLowerCase().includes(search.toLowerCase()) && search) return;
                                    if (data.assignTo.includes(account.accountID)) return;

                                    accountCount++;


                                    return (
                                        <div className="select-none flex items-center gap-2 p-2 hover:bg-blue-500 group" onClick={() => handleAssignee(account.accountID)} key={account.accountID}>
                                            <div className="w-5 flex justify-center items-center">{data.assignTo.includes(account.accountID) && <FaCheck className="text-green-500"/>}</div>
                                            <div className="size-6 text-sm rounded-full bg-blue-500 text-white flex justify-center items-center">{account.name.charAt(0)}</div>
                                            <div className="w-full items-center flex-row flex gap-2">
                                                <h5 className="font-bold  text-base">{account.username}</h5>
                                                <span className="text-sm">&#8226;</span>
                                                <h5 className="text-gray-500 text-sm group-hover:text-white">{account.name}</h5>
                                            </div>
                                        </div>
                                    )

                                })}
                            </div>
                        </div>
                    </Modal>    
                    }
                </div>

                

                {/* Select type */}
                <div>
                    <div className="w-full items-center flex-row flex gap-2">
                        <h5 className="font-bold text-base">Wat voor taak is het?</h5>
                    </div>
                    <div className="flex">
                        <button
                            className={`active:border-dark-secondary w-full text-sm bg-dark-input-bg h-12 rounded-s border border-dark-primary p-2 outline-none ${data.type === 'single' ? 'bg-dark-secondary' : ''}`}
                            onClick={() => setData(prev => ({
                                ...prev,
                                type: 'single'
                            }))}
                        >
                            Eenmalige Taak
                        </button>

                        <button
                            className={`active:border-dark-secondary w-full text-sm bg-dark-input-bg h-12 rounded-r border border-dark-primary p-2 outline-none ${data.type === 'repeat' ? 'bg-dark-secondary' : ''}`}
                            onClick={() => setData(prev => ({
                                ...prev,
                                type: 'repeat'
                            }))}                        
                        >
                            Herhalende Taak
                        </button>
                    </div>

                </div>

                {/* Type = single : Deadline */}
                {data?.type === 'single' && <Input label="Deadline" name="deadline" type='date' data={data} setData={setData}/>}

            {/* Type = Repeat : repeatFrom */}
                {data?.type === 'repeat' && <Input label="Herhaal vanaf" name="repeatFrom" type='date' data={data} setData={setData}/>}

            {/* Type = Repeat : repeatUntil */}
                {data?.type === 'repeat' && <Input label="Herhaal tot" name="repeatUntil" type='date' data={data} setData={setData}/>}
            {/* Type = Repeat : repeatEvery */}
                {data?.type === 'repeat' && <Input label="Herhaal elke" name="repeatEvery" type='number' data={data} setData={setData}/>}
            {/* Type = Repeat : repeatUnit */}
                {data?.type === 'repeat' && <div>
                    <div className="w-full items-center flex-row flex gap-2">
                        <h5 className="font-bold text-base">Tijdseenheden</h5>
                    </div>
                        <select
                            className="focus:border-dark-secondary bg-dark-bg border-dark-primary rounded border p-2 outline-none w-full"
                            name="repeatUnit"
                            value={data.repeatUnit}
                            onChange={(event) => setData(prev => ({
                                ...prev,
                                repeatUnit: event.target.value
                            }))}      
                        >
                            <option value="" disabled>Tijdseenheden</option>
                            <option value="DAYS">Dagen</option>
                            <option value="WEEKS">Weken</option>
                            <option value="MONTHS">Maanden</option>
                            <option value="YEARS">Jaren</option>
                        </select>
                    </div>
                }
                <button
                    className="hover:bg-dark-secondary transition-all duration-300 active:border-dark-secondary w-full bg-dark-input-bg h-12 rounded border border-dark-primary p-2 outline-none"
                    onClick={saveTask}
                >
                    {data?.type === 'repeat' ? `Herhalende taak aanmaken` : `Taak aanmaken`}
                </button>
            </div>
        </div>
    )
}

function Input({label, name, charLimit, type, data, setData}) {

    const onChange = (event) => {
        const newValue = event.target.value;
    
        // If there is no character limit, set the new value
        if (!charLimit) {
            setData(prev => ({
                ...prev,
                [event.target.name]: newValue
            }));
        }

        // If the new value's length is within the limit
        if (newValue.length <= charLimit) {
            setData(prev => ({
                ...prev,
                [event.target.name]: newValue
            }));
        } else {
            // If the new value exceeds the limit, truncate it to the limit
            setData(prev => ({
                ...prev,
                [event.target.name]: newValue.slice(0, charLimit)
            }));
        }
    };

    return (
        <div>
            <div className="w-full items-center flex-row flex gap-2">
                <h5 className="font-extrabold">{label}</h5>
                {charLimit && <span className="text-sm">&#8226;</span>}
                {charLimit && <span className={data[name].length >= charLimit ? `text-red-500` : `text-gray-500`} >({data[name].length}/{charLimit})</span>}
            </div>
            <input
                type={type ? type : "text"}
                className="focus:border-dark-secondary bg-dark-bg border-dark-primary rounded border p-2 outline-none w-full"
                name={name}
                value={data[name]}
                onChange={onChange}
                placeholder={label}
            />
        </div>
    )
}
import React from "react";
import { useGetDataQuery } from "../../logic/apiSlice";
import { Link, useLocation } from "react-router-dom";

import {
    IoHomeOutline,
    IoHome,
    IoSearchOutline,
    IoSearch,
    IoNotifications,
    IoNotificationsOutline,
    IoMail,
    IoMailOutline,
    IoApps,
} from "react-icons/io5";
import { BsPeople, BsPeopleFill } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { showToast } from "../../app/redux-reducers/contextProvider";
import apiService from "../../logic/apiService";

function Footer({ settings }) {
    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { refetch, data: accountData, isSuccess } = useGetDataQuery("/account/user");

    function logOut() {
        dispatch(showToast({ message: "Logging out...", level: 0 }));
        // Clear Cache

        apiService.post("/auth/logout").then(() => {
            refetch();
            navigate("/auth/login");
        });
    }


    return (
        <div className="flex w-full flex-col items-end sm:w-20 sm:gap-4">
            <div className="border-t-dark-primary sm:bg-dark-bg-shade bg-dark-bg-0.95 fixed bottom-0 z-10 flex h-20 w-full flex-row items-center justify-around border-t sm:relative sm:bottom-auto sm:h-auto sm:w-14 sm:flex-col sm:gap-2 sm:rounded-xl sm:border-0 sm:p-2">
                {renderFooter(location, settings)}
            </div>
            <div className="border-t-dark-primary sm:bg-dark-bg-shade bg-dark-bg-0.95 fixed bottom-0 z-10 hidden h-20 w-full flex-row items-center justify-around border-t sm:relative sm:bottom-auto sm:flex sm:h-auto sm:w-14 sm:flex-col sm:gap-2 sm:rounded-xl sm:border-0 sm:p-2">
                <Link
                    to="/apps"
                    className={`sm:hover:bg-dark-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/apps") ? (
                        <IoApps className="text-dark-secondary size-9" />
                    ) : (
                        <IoApps className="text-dark-primary size-9" />
                    )}
                </Link>
                
                <button
                    onClick={() => logOut()}
                    className="sm:hover:bg-dark-bg-0.5 hidden sm:flex sm:rounded-lg sm:p-1 sm:duration-300"
                >
                    <MdLogout className="text-dark-primary size-9" />
                </button>
            </div>
        </div>
    );
}

export default Footer;

function renderFooter(location, settings) {
	return settings.map((item) => {
		return (
			<Link to={item.path} key={item.path} className="sm:hover:bg-dark-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300">
			{location.pathname === item.path ? (
				<item.iconActive className="size-9 text-dark-secondary" />
			) : (
				<item.icon className="size-9 text-dark-primary" />
			)}
		</Link>
		)
	})
}
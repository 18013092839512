import React from "react";
import { useNavigate } from "react-router-dom";
import { FaWifi } from "react-icons/fa";
import { MdDryCleaning } from "react-icons/md";
import { IoHome } from "react-icons/io5";

import { useEffect } from "react";
import apiService from "../logic/apiService"
export default function Apps() {
    const navigate = useNavigate();

    return (
        <>
            <App 
                title="Home"
                description="De startpagina!"
                image={<IoHome className="size-full"/>}
                callback={() => navigate("/")}
            />
            <App 
                title="Klusjes"
                description="Klusjes afvinken was nog nooit zo makkelijk!"
                image={<MdDryCleaning className="size-full"/>}
                callback={() => navigate("/klusjes/dashboard")}
            />
            <App 
                title="Wifi"
                description="Maak gebruik van ons wifi netwerk!"
                image={<FaWifi className="size-full"/>}
                callback={() => navigate("/wifi")}
            />
        </>
    );
}

function App({title, description, image, callback}) {
	return (
        <div onClick={callback} className="cursor-pointer last:mb-20 max-w-3xl w-full flex flex-col gap-2.5 px-5 py-3 last:border-none border-b border-b-dark-primary min-h-20">
			{/* Content */}
			<div className="w-full flex gap-2.5 flex-row">
				{/* Profile picture */}
				{typeof image === 'object' ? <div className="h-12 w-12 rounded">{image}</div> : <img className="size-12 rounded" src={image || null} />}
				{/* Content */}
				<div className="w-full flex flex-col gap-2">
					{/* Header */}
					<div className="w-full items-center flex-row flex gap-2">
						<h5 className="select-none font-bold text-base">{title || `APP_NAME`}</h5>
					</div>
					{/* Body */}
					<div className="w-full overflow-hidden">
						<p className="select-none text-sm w-11/12 overflow-hidden text-ellipsis whitespace-nowrap">
							{description || `APP_DESCRIPTION`}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}